.pages {
  &__center {
    text-align: center;
  }
  &__logo {
    max-width: 50%;
    @media (max-width: 414px) {
      max-width: 70%;      
    }
  }
  &__icon {
    max-width: 100px;
    padding-right: 1rem;
  }

  &__option {
    flex-direction: column;
    display: flex;
    justify-content: center;
    text-align: center;
    @media (max-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    @media (max-width: 414px) {
      padding: 1.5rem;
    }

    &-details {
      @media (max-width: 768px) {
        display: flex;
        align-items: center;
      }
    }
    &-icon {
      font-size: 60px;
      color: #ff5a5f;
      @media (max-width: 768px) {
        font-size: 40px;
        width: 50px;
        margin-right: 1rem;
      }
    }
  }
  &__masonary {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1em;
    -moz-column-gap: 1em;
    column-gap: 1em;
    -moz-column-gap: 1em;
    -webkit-column-gap: 1em;
    column-gap: 1em;
    @media (max-width: 768px) {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }

    &-item {
      display: inline-block;
      margin: 0 0 1em;
      width: 100%;
      -webkit-transition: 1s ease all;
    }
  }
  &__article{
    &-copy{
      color: #2A29A4;
    }
    &-author{
      margin: 2rem 0 1rem;
    }
  }
}

.programs {
  & + &__loaders {
    display: none;
  }
  &__loading {
    text-align: center;
  }
  &__alert {
    display: none;
  }
}

.skill-group {
  &--disabled {
    pointer-events: none;
    cursor: initial;
  }
}

body {
  background-image: url('../assets/images/cm-path-2.svg');
  background-size: calc(768px - 2rem);
  background-position: center;
  background-repeat: repeat-y;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  @media (max-width: 768px) {
    background-size: calc(414px - 2rem);
  }
}
