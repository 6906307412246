@import './scss/fontawesome.scss';
@import './scss/solid.scss';
@import './scss/regular.scss';
@import './scss/light.scss';
@import './scss/brands.scss';
 
.fas{
  @extend .fas;
}
.far{
  @extend .far;
}
.fal{
  @extend .fal;
}
.fab{
  @extend .fab;
}

.fa-check {
  @extend %fa-icon;

  &:before {
    content: fa-content($fa-var-check);
  }
}