/* Fonts */
@font-face {
  font-family: "circular-black";
  src: url("./fonts/EFCircularWeb-Black.woff2") format("woff2"),
}
@font-face {
  font-family: "circular-bold";
  src: url("./fonts/EFCircularWeb-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "circular-medium";
  src: url("./fonts/EFCircularWeb-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "circular-book";
  src: url("./fonts/EFCircularWeb-Book.woff2") format("woff2");
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/fa-light-300.eot');
  src: url('./fonts/fa-light-300.eot?#iefix') format('embedded-opentype'),
  url('./fonts/fa-light-300.woff2') format('woff2'),
  url('./fonts/fa-light-300.woff') format('woff'),
  url('./fonts/fa-light-300.ttf') format('truetype'),
  url('./fonts/fa-light-300.svg#fontawesome') format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/fa-regular-400.eot');
  src: url('./fonts/fa-regular-400.eot?#iefix') format('embedded-opentype'),
  url('./fonts/fa-regular-400.woff2') format('woff2'),
  url('./fonts/fa-regular-400.woff') format('woff'),
  url('./fonts/fa-regular-400.ttf') format('truetype'),
  url('./fonts/fa-regular-400.svg#fontawesome') format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/fa-brands-400.eot');
  src: url('./fonts/fa-brands-400.eot?#iefix') format('embedded-opentype'),
  url('./fonts/fa-brands-400.woff2') format('woff2'),
  url('./fonts/fa-brands-400.woff') format('woff'),
  url('./fonts/fa-brands-400.ttf') format('truetype'),
  url('./fonts/fa-brands-400.svg#fontawesome') format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/fa-solid-900.eot');
  src: url('./fonts/fa-solid-900.eot?#iefix') format('embedded-opentype'),
  url('./fonts/fa-solid-900.woff2') format('woff2'),
  url('./fonts/fa-solid-900.woff') format('woff'),
  url('./fonts/fa-solid-900.ttf') format('truetype'),
  url('./fonts/fa-solid-900.svg#fontawesome') format('svg');
}