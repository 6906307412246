.progress-bar{
  background: rgba(0,0,0,0.1);
  border-radius: .5rem;
  padding: 1rem;
  display: block;

  &__row.row{
    align-items: center;
  }

  &__path{
    background: #1f1e75;
    height: 7px;
    width: 100%;
    border: 1px solid white;
    position: relative;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 414px) {
      height: 5px;
      border-radius: 3px;
    }

    &-indicator{
      height: calc(100%);
      top: 0;
      left: 0;
      background: #FF5A5F;
      position: absolute;
      border-radius: 4px;
      @media (max-width: 414px) {
        border-radius: 3px;
      }
    }
  }
  &__milestone{
    position: relative;
    z-index: 1;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    border: 1px solid white;
    background: #1f1e75;
    @media (max-width: 414px) {
      height: 15px;
      width: 15px;
      border-radius: 7px;
    }

    &--active{
      background:#FF5A5F;
    }
  }
}
